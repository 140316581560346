<template>
  <div class="MainTexteMobile">
    <div class="MainTexteMobile--text-container">
      <!-- <div class="MainTexteMobile--text-container--rubrique">{{content.rubrique}}</div> -->
      <div class="MainTexteMobile--text-container--labeur" v-footnotes2 v-html="content.text"></div>
    </div>

    <div class="MainTexteMobile--notes" v-footnotes :data-notes="content.notes">
      <div class="MainTexteMobile--notes--element" v-html="content.int_text"></div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'MainTexte',
  props: ['content', 'scrollSequence'],
  data: function () {
    return {
      footnotesState: [],
      footnotesDisplay: [],
    }
  },
  directives: {

    footnotes:{
      bind: function(el, binding, vnode){
        let frag = document.createRange().createContextualFragment(el.dataset.notes);
        let footnotesNodes = frag.querySelectorAll('li')
        let footnotesArray = Array.prototype.slice.call(footnotesNodes)
        el.removeAttribute('data-notes')

        footnotesArray.forEach((element) => {
          // vnode.context.footnotesState.push(element.innerHTML.trim())
          let elementRaw = element.innerHTML.trim()
          let split = elementRaw.split(element.value + '.')
          // let elementFormated = element.value + '. ' + split[1]
          let elementFormated = '<span class="NotesNumber">(' + element.value + '). </span>' + split[1]

          let elementInjected = split.length > 1 ? elementFormated : elementRaw

          vnode.context.footnotesState.push(elementInjected)
        })
      }
    },

    footnotes2: {
      inserted: function(el, binding, vnode){

        let supNotesNodes = el.querySelectorAll('.footnote')
        let supNotesArray = Array.prototype.slice.call(supNotesNodes)

        supNotesArray.forEach((element, index) => {
          let newNoteElement = document.createElement('span')
          newNoteElement.classList.add('note')
          newNoteElement.dataset.ref = index
          newNoteElement.innerHTML = vnode.context.footnotesState[index]

          let cibleInsertDOM = element.parentNode
          cibleInsertDOM.appendChild(newNoteElement)
        })
      }
    }
  },
  computed: {

    scrollSequenceFootnote: function(){
      return this.scrollSequence + '-footnote'
    },

    supNoteIsVisible: function(){
      return this.footnotesDisplay.map(function(item){
        return item
      })
    },

    footnotes: function(){
      return this.footnotesState
    }
  },

  methods: {
    sendScrollData(dimension){
      // console.log(dimension)
      this.$emit('send-height', {text: dimension})
    },
  },
  mounted(){

    this.$tools.setResizeObserver(this.$el, () => {
      // console.log('MAINARTICLE SEND HEIGHT')
      this.sendScrollData(this.$el.getBoundingClientRect().height)
    })

  }
}

</script>

<style lang="scss">
@import '@/css/variables';

.MainTexteMobile{
  width: 100%;

  min-height: calc(100vh - var(--menu-header-height));

  &--text-container{
    margin-left: var(--esp-big);
    margin-right: var(--esp-big);


    &--rubrique{
      position: relative;
      font-size: var(--article-font-text);
      font-family: 'vd-reg';
      line-height: 1;
      text-transform: uppercase;

      text-align: center;

      padding-top: calc( 2 * var(--esp-big));
      padding-bottom: var(--esp-big);
    }

    &--labeur{
      padding-top: calc(var(--esp-big));
      font-family: 'vd-reg';
      font-size: var(--article-font-text);
      line-height: 1.2; 

      // padding-bottom: 10vh;

      & p{
        margin-bottom: var(--article-font-text);
        text-align: 1;
      }
      & em{
        font-family: 'vd-ita';
      }

      & strong{
        letter-spacing: 0.4vw;
        text-decoration: underline;
      }   

      & sup{
        font-size: var(--article-font-notes);
        position: relative;
        text-indent: 0px;

        // height: var(--article-font-notes);

        color: var(--article-color);

        // vertical-align: bottom;
        text-decoration: none;
        font-size: var(--article-font-text);

        font-family: 'oli';
        font-size: calc( 0.6 * var(--article-font-text));
      }

      & sup:before{
        content: "(";
      }

      & sup:after{
        content: ")";
      }

      // à vérifier
      & span{
        // display: block;
      }
    }
  }
}



li {
  & .note:last-child{
    // background-color: red;
    margin-bottom: var(--esp-big);
  }
}

.note{
  text-indent: 0px;
  display: block;
  margin-left: 35px;
  margin-top: var(--esp-small);
  width: calc(100% - 35px);

  font-size: var(--article-font-notes);
  color: var(--article-color);

  // line-height: 1.2;
}

// h1{
//   text-align:center;
//   text-decoration: underline;
//   margin-bottom: var(--esp-med);
// }

.MainTexteMobile--text-container--labeur{

  & h1{ // titre 
    text-align: center;
    text-decoration: underline;
    margin-bottom: var(--esp-big);
  }

  & h2{
    text-indent: 35px;
  }

  & h3{ // break
    display: none;
  }

  & h4{ //
    margin-left: 35px;
    // margin-bottom: var(--esp-small);
  }

  & h5{

  }

  & hr{
    border:0;
    border-top:0px solid #555;
    height:0px;
    margin-bottom: var(--esp-big);
  }

  & ul {
    text-decoration: underline;
    margin-left: 35px;
        
  }

  /*PARAGRAPHES*/

  & ol {
    & li:first-child{
      text-indent: 0px;
    }     
    & li {
      margin-bottom: var(--esp-big);
      text-indent: 35px;

      & a {
        word-break: break-word;
      }
    }
  }
}

.NotesNumber{
  font-family: 'oli';

}

.MainTexteMobile--notes--element{

  margin-left: var(--esp-big);
  margin-right: var(--esp-big);

  font-family: 'vd-reg';
  line-height: 1.2;
  font-size: var(--article-font-notes);

  color: var(--article-color);

  // margin-bottom: 1vw;

  width: calc(100% - (2 * var(--esp-big )));

  padding-bottom: 10vh;

  &:first-child{
    margin-top: var(--esp-big);
  }
}


</style>