<template>
	<div class="ArticleMobile" 
  :style="{height: whatHeight()}">   

      <CartoucheMobile 
      v-if="loadedData"
      :content="page.cartouche"
      @send-height="addDimension"  
      ></CartoucheMobile>

      <MainArticleMobile
      v-if="loadedData"
      :articleType="articleType" 
      :content="page.main" 
      :slideIndex="slideIndex"
      :touchSliderActive="touchSliderActive"
      @send-height="addDimension"  
      ></MainArticleMobile>

      <RedirectMobile
      v-if="loadedData"
      :content="page.redirect" 
      @send-height="addDimension" 
      ></RedirectMobile>

      <Scrollbar :parent="'article'"></Scrollbar>

  </div>
</template>

<script>
import CartoucheMobile from '@/components/article/mobile/CartoucheMobile.vue'
import MainArticleMobile from '@/components/article/mobile/MainArticleMobile.vue'
// import RedirectMobile from '@/components/article/mobile/RedirectMobile.vue'
import RedirectMobile from '@/components/article/Redirect2.vue'

import Scrollbar from '@/components/scrollbar/Scrollbar.vue'

import clientConfigs from '@/mixins/clientConfigs.js'
import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'ArticleMobile',
  mixins: [clientConfigs],
  data: function () {
   return { 
     state : this.$store.state,
     loadedData: true,

     ArticleConfig: [],

     slideIndex: 0,
     touchMovePositionX: '',
     touchMovePositionY: '',
     touchDirection: '',

     touchSliderActive : true,
     touchVerticalActive : true,

     touchStartPositionY : '',
     touchEndPositionY: '',

     touchStartPositionX: '',
     touchEndPositionX: '',

     scrollTop: 0,
     scrollSequence: 0,

     scrollState: {
      cartouche: '',  // propriété reçu par emit 
      video: '',
      viewer: '', // propriété reçu par emit  
      text: '',
      redirect: '' // propriété reçu par emit
     },

     scrollCoordinate: [],

     scrollType: {
      texteImages: ['cartouche', 'viewer', 'text', 'redirect'],
      texteImagesWithVideo: ['cartouche', 'video','viewer', 'text', 'redirect'],
      images: ['cartouche','viewer', 'redirect'],
      itw: ['cartouche', 'viewer', 'text', 'redirect'],
      texte: ['cartouche', 'text', 'redirect'],
      video: ['cartouche', 'video', 'redirect'],
     },

     scrollConfig: {
      cartouche: {
        up: false,
        down: false, // true
        left: false,
        right: false
      },
      video: {
        up: false, // true
        down: false, // true
        left: false,
        right: false   
      },
      viewer: {
        up: false, // true
        down: false, // true
        left: true, // true
        right: true // true  
      },
      text: {
        up: false, // true
        down: false, // true
        left: false,
        right: false   
      },
      redirect: {
        up: false, // true
        down: false,
        left: false,
        right: false   
      },
     }
   }
  },
  components: {
    CartoucheMobile,
    MainArticleMobile,
    RedirectMobile,
    Scrollbar
  },
  watch: {

    scrollState: {
      handler(){

        let ArticleTypeComposants = this.scrollType.[this.articleType]
        let scrollTypeLength = ArticleTypeComposants.length

        let receipt = 0 
        ArticleTypeComposants.forEach((element)=> {
          if (this.scrollState.[element]){
            receipt = receipt + 1
          }
        })
        if (receipt == scrollTypeLength){
          this.initTabArticleConfig()
        }
      },
      deep: true
    }
  },

  computed: {
    page: function () { return this.$store.state.[this.$route.params.language].[this.$route.name] },

    ColorArticle: function(){ 
      let artColor = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color');
      let amateurColor = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color');

      let artColorLighten = window.getComputedStyle(document.documentElement).getPropertyValue('--first-color-lighten');
      let amateurColorLighten = window.getComputedStyle(document.documentElement).getPropertyValue('--second-color-lighten');

      return [this.page.category === 'art' ? artColor : amateurColor, this.page.category === 'art' ? artColorLighten : amateurColorLighten]; 
    },
    articleType: function(){ 
      return this.page.articleType
    },
  },
  methods: {
    whatHeight(){
      if ( this.ClientDevice != 'desktop' || this.ClientFormat == 'portrait'){
       
        let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');

        let pixelTotalHeight = window.innerHeight 
        let pixelMargeHeight = margeArticleRaw.split('px')[0]
        let pixelContentHeight = window.innerHeight - pixelMargeHeight

        let pourentContentHeight = pixelContentHeight * 100 / pixelTotalHeight
        pourentContentHeight

        return ''

      }else {
        return ''
      }

    },

    addDimension: function(dimension){
      this.$set(this.scrollState, Object.keys(dimension)[0], dimension.[Object.keys(dimension)[0]])
    },

    initTabArticleConfig: function(){

      let ArticleTypeComposants = this.scrollType.[this.articleType]  // ['cartouche', 'viewer', 'text', 'redirect']
      ArticleTypeComposants.forEach((element, index) => {
        
        // tab scrollCoordinate
        if (index > 0 ){
          this.scrollCoordinate[index] = this.scrollCoordinate[index - 1] + this.scrollState.[element]
        }else {
          this.scrollCoordinate[index] = this.scrollState.[element]
        }

        // tab ArticleConfig
        let positionTop = index == 0 ? 0 : this.scrollCoordinate[index - 1]
        let positionBottom = index == 0 ? this.scrollState.[element] : this.scrollCoordinate[index - 1] + this.scrollState.[element]
        
        let ActualType = this.scrollType.[this.articleType]  // ['cartouche', 'viewer', 'text', 'redirect']
        let ActualSequenceType = ActualType[index]   // 'text'
        let ActualSequenceConfig = this.scrollConfig[ActualSequenceType]  // OBJ // up: flase, down: true, left: false, right : true 

        this.ArticleConfig[index] = {
          type: element,
          position: {
            top: positionTop,
            bottom: positionBottom,
          },
          config: ActualSequenceConfig
        }

      })
    },
    onResize: function(){
      this.$forceUpdate();
    }
  },

  created (){

    window.scroll(0, 0)

    document.documentElement.style.setProperty('--article-color', this.ColorArticle[0])
    document.documentElement.style.setProperty('--article-color-lighten', this.ColorArticle[1])

    smoothscroll.polyfill();

    window.addEventListener('resize', this.onResize) 
    this.$client.isInit = true
  },

  mounted(){
    // console.log('isIos', this.$client.isOldIos())
  },

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.ArticleMobile{
  z-index: 2;
  font-size: 2.3vw;
  line-height: 3.5vw;
  background-color: white;

  left: 0;
  top: var(--menu-header-height);

  position: absolute;

  height: auto;
  overflow: scroll;
  width: 100vw;
}

</style>