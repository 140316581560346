<template>
	<div class="CartoucheMobile" :class="paddingIsActive == true ? 'padding-is-active' : ''" >
		<div class="CartoucheMobile--text-container">
			<div class="CartoucheMobile--text-container--title" v-html="content.title"><!--  {{ content.title }}  --></div>

			<Share :title="content.title" :texte="content.text" :authors="content.authors" ></Share>
			<div class="CartoucheMobile--text-container--main" v-html="content.text"><!-- {{ content.text }} --></div>
			<div class="CartoucheMobile--text-container--authors">

				<div>
					<template v-if="content.authors.text">
						<div v-if="$route.params.language == 'fr'">Texte&nbsp;: {{content.authors.text}}</div>
						<div v-if="$route.params.language == 'en'">Words&nbsp;: {{content.authors.text}}</div>
					</template>
					<template v-if="content.authors.photo">
						<div v-if="$route.params.language == 'fr'">Photographies&nbsp;: {{content.authors.photo}}</div>
						<div v-if="$route.params.language == 'en'">Photos&nbsp;: {{content.authors.photo}}</div>
					</template>
					<template v-if="content.authors.more">
						<template v-for="(author, index) in content.authors.more">
							<template v-if="content.authors.more.length - 1 == index">
								<div :key="index" class="authors-flex" v-html="author.categorie + '&nbsp;: ' + author.name"></div>
							</template>
							<template v-else>
								<div :key="index" class="authors-flex" v-html="author.categorie + '&nbsp;: ' + author.name"></div>
							</template>
						</template>
					</template> 

				</div>

			</div>

			<div class="CartoucheMobile--text-container--date">{{whatContentDate}}</div>


		</div>
	</div>
</template>

<script>
	import Share from '@/components/article/cartouche/Share.vue'
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'CartoucheMobile',
  props: ['content'],
  data: function(){
		return {
			paddingIsActive: ''
		}
  },
  components: {
		Share
  },
  directives: {
		LazyLoading
  },
  computed: {
    whatContentDate: function(){
			if (this.$route.params.language == 'fr'){
				return 'Publié dans Profane n°' + this.content.isProfaneNumber + ', ' + this.content.isSaison
			}else {
				return 'Published in Profane n°' + this.content.isProfaneNumber + ', ' + this.content.isSaison
			} 
		}
  },
  methods: {
    sendScrollData(dimension){
      this.$emit('send-height',{cartouche: dimension})
    },
		setHeight(){ // ajuster height en fonction du contenu (après initHeight)

			let menuHeightRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--menu-header-height');
			let menuHeight = Number(menuHeightRaw.split('px')[0])

			if (this.$el.getBoundingClientRect().height + menuHeight > window.innerHeight){
				this.paddingIsActive = true
			}else {
				this.paddingIsActive = false
			}
		},
  },

  mounted () {
		this.$tools.setResizeObserver(this.$el, () => {
			this.setHeight()
			this.sendScrollData(this.$el.getBoundingClientRect().height)
		})
  }
}

</script>

<style lang="scss">
@import '@/css/variables';

.CartoucheMobile{
	width: 100%;
	min-height: calc(90vh - var(--menu-header-height));
	background-color: var(--article-color);
	display: flex;
	justify-content: space-between;

	&.padding-is-active{
		// padding-bottom: 8vh;
	}

	&--text-container{
		margin: var(--esp-big);
		max-width: 100%;

		&--title{
			color: white;
			font-size: var(--cartouche-font-title);
			line-height: 0.9;
			// word-break: break-word;

			overflow-wrap: anywhere;
			
			@supports ( hyphens: auto ) {
				hyphens: auto;
				-webkit-hyphens: auto;
				-moz-hyphens: auto;
				-ms-hyphens: auto;
			}
		}

		&--main{
			margin-top: 1vw;
			color: white;
			font-family: 'vd-reg';

			// text-decoration: underline solid white 0.5vw;
			// -webkit-text-decoration: underline solid white 0.5vw;
			// // text-underline-offset: 0.5vw;

			font-size: var(--cartouche-font-text);
			line-height: 1.1;
			margin-top: calc( 2 * var(--esp-big));


			text-decoration-line: underline;
			-webkit-text-decoration-line: underline;
			text-decoration-color: white;
			-webkit-text-decoration-color: white;
			text-underline-offset: 0.3vw;
			-webkit-text-underline-offset: 0.3vw;
		}
		&--authors{

			color: white;
			font-family: 'vd-reg';
			font-size: var(--cartouche-font-paratexte);
			line-height: 1.2;

			margin-top: calc( 2 * var(--esp-big));
		}

		&--date{
			font-size: var(--cartouche-font-paratexte);
			color: white;
			font-family: 'vd-reg';
			line-height: 1.2;
		}
	}
}

.authors-flex{
	& em {
		font-family: 'vd-ita';
	}
}


</style>
