<template>
  <div class="MainVideoMobile">

    <transition name="fade-main-video" mode="out-in">

    <div class="MainVideoMobile--poster-container"
    v-if="!videoStarted" 
    :style="{'height': iframe_height() + 'px'}"
    @click="playVideo"
    >

      <div class="MainVideoMobile--poster-container--button"
      v-if="!videoStarted" 
      :style="{'top': iframe_height() / 2 + 'px'}"
      ></div>

      <img
      class="MainVideoMobile--poster-container--poster"
      :srcset="content.poster"
      :style="{'height': iframe_height() + 'px'}"
      >

    </div>

    </transition>

    <div class="MainVideoMobile--fullscreen-security"
    v-if="fullscreenSecurity"
    :style="{'height': iframe_height() + 'px'}"
    @click="clickSecurity"
    ></div>

    <div class="wrap-mobile"
    :style="{'opacity': videoStarted == true ? 1:0}"
    >
    <iframe 
    id="ytplayer" 
    type="text/html" 
    :width="iframe_width" 
    :height="iframe_height"
    
    frameborder="0"

    :style="{'height': iframe_height() + 'px'}"
    :src="link_video"

    allowfullscreen
    />
    </div>
    <!-- allowfullscreen -->

  </div>
</template>

<script>
export default {
  name: 'MainVideoMobile',
  props: ['content'],
  data: function(){
    return {
      videoStarted: false,
      fullscreenSecurity: false
    }
  },
  computed: {
    link_video: function(){
      return 'https://www.youtube.com/embed/'+ this.content.id + '?rel=0&controls=1&showinfo=1&modestbranding=1&enablejsapi=1&loop=1'
    }
  },
  methods: {
    playVideo: function(ev){
      document.querySelector('#ytplayer').src += "&autoplay=1";
      ev.preventDefault();

      this.videoStarted = true
      this.$client.removeListener()
      this.setVideoListener()
    },
    setVideoListener(){
      document.addEventListener('fullscreenchange', this.videoListener)
      document.addEventListener('msfullscreenchange', this.videoListener)
      document.addEventListener('mozfullscreenchange', this.videoListener)
      document.addEventListener('webkitfullscreenchange', this.videoListener)
    },
    removeVideoListener(){
      document.removeEventListener('fullscreenchange', this.videoListener)
      document.removeEventListener('msfullscreenchange', this.videoListener)
      document.removeEventListener('mozfullscreenchange', this.videoListener)
      document.removeEventListener('webkitfullscreenchange', this.videoListener)
    },
    videoListener(){
      if (document.fullscreenElement) {
        // console.log(`Element: ${document.fullscreenElement.id} entered full-screen mode.`);
        // console.log(document.fullscreenElement)
        // console.log(document)
      } else {
        // console.log('Leaving full-screen mode.');
        this.$client.setListener()
        this.fullscreenSecurity = true
      }
    },
    clickSecurity(){
      this.fullscreenSecurity = false
      this.$client.removeListener()
    },
    iframe_width: function(){
      let margeArticleRaw = window.getComputedStyle(document.documentElement).getPropertyValue('--esp-big');
      let margeArticle = Number(margeArticleRaw.split('vw')[0])
      return window.innerWidth - (2 * margeArticle * window.innerWidth / 100)
    },
    iframe_height: function(){
      return this.iframe_width() * 1080 / 1920
    },
    sendScrollData(dimension){
      this.$emit('send-height', {video: dimension})
    },
  },
  mounted(){
    this.$tools.setResizeObserver(this.$el, () => {
      // console.log('MAINARTICLE SEND HEIGHT')
      this.sendScrollData(this.$el.getBoundingClientRect().height)
    })
  },

  beforeDestroy(){
    this.$client.setListener()
    this.removeVideoListener()
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.MainVideoMobile{
	height: calc(90vh - var(--menu-header-height));
	width: 100%;
	background-color: var(--article-color-lighten);
}


.wrap-mobile{
  width: calc(100% - (2 * var(--esp-big)));
  height: 100%;
  margin-left: var(--esp-big);
  margin-right: var(--esp-big);

  & iframe{
    width: 100%;
    // height: 24%;
    margin-top: var(--esp-big);
  }

}


.MainVideoMobile--poster-container{
  position: absolute;
  width: calc(100% - (2 * var(--esp-big)));
  margin-left: var(--esp-big);
  margin-right: var(--esp-big);
  overflow: hidden;

  height: 100%;
  z-index: 10;

  cursor: pointer;

  margin-top: var(--esp-big);

  &--button{
    position: absolute;
    width: 25px;
    height: 25px;


    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 11;


    width: 0;
    height: 0;

    border-top: 25px solid transparent;
    border-left: 50px solid var(--article-color-lighten);
    border-bottom: 25px solid transparent;
  }

  &--poster{

    width: 100%;
    
    transform: scale( 1.01);

    // height: 100%;

    // background-color: red;

    object-fit: cover;
  }
}


.MainVideoMobile--fullscreen-security{
  position: absolute;
  width: calc(100% - (2 * var(--esp-big)));
  margin-left: var(--esp-big);
  margin-right: var(--esp-big);
  margin-top: var(--esp-big);
}

</style>

    

