<template>
  <div class="MainItwMobile">
    <div class="MainItwMobile--text-container">
      <!-- <div class="MainItwMobile--text-container--rubrique">{{content.rubrique}}</div> -->
      <div class="MainItwMobile--text-container--text" v-html="content.text"></div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'MainTexte',
  props: ['content', 'scrollSequence'],
  data: function () {
    return {
    }
  },

  computed: {

  },

  methods: {
    sendScrollData(dimension){
      // console.log(dimension)
      this.$emit('send-height', {text: dimension})
    },
  },
  mounted(){

    this.$tools.setResizeObserver(this.$el, () => {
      this.sendScrollData(this.$el.getBoundingClientRect().height)
    })

  }
}

</script>

<style lang="scss">
@import '@/css/variables';

.MainItwMobile{
  width: 100%;

  min-height: calc(100vh - var(--menu-header-height));
  padding-bottom: 10vw;

  &--text-container{
    margin-left: var(--esp-big);
    margin-right: var(--esp-big);

    &--rubrique{
      position: relative;
      font-size: var(--article-font-text);
      font-family: 'vd-reg';
      line-height: 1;
      text-transform: uppercase;
      left: 20%;

      margin-top: calc( 2 * var(--esp-big));
      margin-bottom: var(--esp-big);

    }

    // &--text{
    //   font-family: 'vd-reg';
    //   font-size: var(--article-font-text);
    //   line-height: 1.2; 

    //   & p{
    //     margin-bottom: var(--article-font-text);
    //     text-align: 1;
    //   }
    //   & em{
    //     font-family: 'vd-ita';
    //   }

    //   & strong{
    //     letter-spacing: 0.5vw;
    //     text-decoration: underline;
    //   }      
    // }

    &--text{
      padding-top: calc(var(--esp-big));
      font-family: 'vd-reg';
      font-size: var(--article-font-text);
      margin-left: var(--esp-med);
      line-height: 1.2; 

      & p {

        & em{
          font-family: 'vd-ita';
        }

        & strong{
          letter-spacing: 0.3vw;
          text-decoration: underline;
        }  
      }


      & p:nth-child(odd){
        margin-left: calc( 3 * var(--esp-med));
        text-decoration: underline;
        color: var(--article-color);
        margin-bottom: calc( 0.2 * var(--article-font-text));
      }

      & p:nth-child(even){
        margin-bottom: var(--esp-big);
      }
    }
  }
}

// span{
//   display: block;
// }

// .note{
//   display: block;
//   margin-left: 10%;
//   margin-top: var(--esp-big);
//   // margin-bottom: var(--esp-big);
//   width: 85%;

//   font-size: var(--article-font-notes);
// }

// h1{
//   text-align:center;
//   text-decoration: underline;
//   margin-bottom: 2vw;
// }

// sup{
//   color: var(--article-color);
//   // font-size: 1.5vw;
//   font-size: calc(0.5 * var(--article-font-text));
//   position: absolute;
//   transform: translate(0.15vw,-0.5vw);

// }

</style>