<template>
  <div class="MainArticleMobile">

    
    <div class="MainArticleMobile--container" v-if="articleType == 'texteImages'">
      <MainViewerMobile 
      :content="content.gallery" 
      @send-height="SendHeight"
      :slideIndex="slideIndex"
      :touchSliderActive="touchSliderActive"
      ></MainViewerMobile>

      <MainTexteMobile
      :content="{text: content.text, int_text: content.int_text, notes: content.notes, rubrique: content.rubrique}" 
      @send-height="SendHeight"
      ></MainTexteMobile>
    </div>


    <div class="MainArticleMobile--container" v-if="articleType == 'texteImagesWithVideo'">
      <MainVideoMobile
      @send-height="SendHeight"
      :content="content.video"
      ></MainVideoMobile>

      <MainViewerMobile 
      :content="content.gallery" 
      @send-height="SendHeight"
      :slideIndex="slideIndex"
      :touchSliderActive="touchSliderActive"
      ></MainViewerMobile>

      <MainTexteMobile
      :content="{text: content.text, int_text: content.int_text, notes: content.notes, rubrique: content.rubrique}" 
      @send-height="SendHeight"
      ></MainTexteMobile>
    </div>


    <div class="MainArticleMobile--container" v-if="articleType == 'images'">
       <MainViewerMobile 
      :content="content.gallery" 
      @send-height="SendHeight"
      :slideIndex="slideIndex"
      :touchSliderActive="touchSliderActive"
      ></MainViewerMobile>     
    </div>


    <div class="MainArticleMobile--container" v-if="articleType == 'video'">
      <MainVideoMobile
      @send-height="SendHeight"
      :content="content.video"
      ></MainVideoMobile>
    </div>

    
    <div class="MainArticleMobile--container" v-if="articleType == 'itw'">
       <MainViewerMobile 
      :content="content.gallery" 
      @send-height="SendHeight"
      :slideIndex="slideIndex"
      :touchSliderActive="touchSliderActive"
      ></MainViewerMobile> 
      
      <MainItwMobile
      :content="{text: content.text, notes: content.notes, rubrique: content.rubrique}" 
      @send-height="SendHeight"
      ></MainItwMobile>
    </div>

    <div class="MainArticleMobile--container" v-if="articleType == 'texte'">
      <MainTexteMobile
      :content="{text: content.text, notes: content.notes, rubrique: content.rubrique}" 
      @send-height="SendHeight"
      ></MainTexteMobile>
    </div>


  </div>
</template>

<script>
import MainTexteMobile from '@/components/article/mobile/mainmobile/MainTexteMobile.vue'
import MainItwMobile from '@/components/article/mobile/mainmobile/MainItwMobile.vue'
import MainViewerMobile from '@/components/article/mobile/mainmobile/MainViewerMobileTape.vue'
import MainVideoMobile from '@/components/article/mobile/mainmobile/MainVideoMobile.vue'

export default {
  name: 'MainArticleMobile',
  props: ['articleType', 'content', 'slideIndex', 'touchSliderActive'],
  data: function () {
    return {
      eventClick: {}
    }
  },
  components: {
    MainTexteMobile,
    MainItwMobile,
    MainViewerMobile,
    MainVideoMobile,
  },

  computed: {

  },
  methods: {
    SendHeight: function(dimension){
      this.$emit('send-height', dimension)
    }
  },
  mounted () {
    
  }

}

</script>

<style lang="scss">
@import '@/css/variables';

.MainArticleMobile{

  overflow: hidden;

}



</style>
