<template>
	<!-- <div class="MainViewerMobileTape" @touchmove="testToucheMove" @click="desktopClick"> -->
	<div class="MainViewerMobileTape">

		<div class="MainViewerMobileTape--touch">
			<div class="MainViewerMobileTape--touch--left" @click="desktopClick(-1)"></div>
			<div class="MainViewerMobileTape--touch--right" @click="desktopClick(1)"></div>
		</div>

		<div 
		class="MainViewerMobileTape--thumbs" 
		ref='slider'
		:style="{transform: whatPosition, left: whatTranslate, width: whatThumbWidth}">

			<img class="MainViewerMobileTape--thumbs--element" 
			:src="content[paginateMax - 1].url" 
			:srcset="content[paginateMax - 1].srcset" 
			:data-legende="content[paginateMax - 1].legende" 
			sizes="100vw">

			<img class="MainViewerMobileTape--thumbs--element" 
			v-for="(image, index) in content" 
			:key="index" 
			:src="image.url"
			:srcset="image.srcset"
			:data-legende="image.legende" 
			sizes="100vw">

			<img class="MainViewerMobileTape--thumbs--element" 
			:src="content[0].url" 
			:srcset="content[0].srcset" 
			:data-legende="content[0].legende" 
			sizes="100vw">

		</div>

<!-- 		<div class="MainViewerMobileTape--pagination">
		<template v-if="index == 0">
			<p>1	/ {{ paginateMax }}</p>
			<div v-html="legendeContent"></div>
		</template>
		<template v-else>
			<p>{{ index }}	/ {{ paginateMax }}</p>
			<div v-html="legendeContent"></div>
		</template>
		</div>

		<div class="MainViewerMobileTape--legende" v-html="legendeContent"> -->

<!-- 		<div class="MainViewerMobileTape--pagination">
		<template v-if="index == 0">
			<p>1	/ {{ paginateMax }}</p>
			<div v-html="legendeContent"></div>
		</template>
		<template v-else>
			<p>{{ index }}	/ {{ paginateMax }}</p>
			<div v-html="legendeContent"></div>
		</template>
		</div> -->

		<div class="MainViewerMobileTape--legende">
		<template v-if="index == 0">
			<div class="MainViewerMobileTape--legende--container">
			<p class="MainViewerMobileTape--legende--container--pagination">1	/ {{ paginateMax }}</p>
			<div class="MainViewerMobileTape--legende--container--content" v-html="legendeContent"></div>
			</div>
		</template>
		<template v-else>
			<div class="MainViewerMobileTape--legende--container">
			<p class="MainViewerMobileTape--legende--container--pagination">{{ index }}	/ {{ paginateMax }}</p>
			<div class="MainViewerMobileTape--legende--container--content" v-html="legendeContent"></div>
			</div>
		</template>			


		</div>

	</div>
</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
	name: 'MainViewerMobileTape',
	mixins: [clientConfigs],
	props: ['content', 'slideIndex', 'touchSliderActive'],
	components: {
	},
	data: function () {
		return {
			controlIndex: this.slideIndex,
			index : 1,

			legendeContent: this.content[0].legende,

			touchMovePosition: '',
			sliderTranslate: '',

			clickCount: 0,

			// touchMoveIsActive: true

		}
	},
	watch: {
		touchSliderActive: function(val){
			// reset slider 
			// if ( !(val)){
				val
				this.sliderTranslate = 0
				this.touchMovePosition = 0
			// }
		},
		slideIndex: function(val){
			if (!(this.$client.isOldIos())){
				
				// this.controlIndex = val


				this.controlIndex = val + this.clickCount
				// console.log(val, val + this.clickCount)
				// this.clickCount = 0
			}
		},
		controlIndex: function(newValue,oldvalue){

			// console.log('controlIndex',oldvalue, newValue)

			this.$el.children[0].style.transition = 'all 0.2s ease'

			this.sliderTranslate = 0
			this.touchMovePosition = ''
			
			let diffValue = oldvalue - newValue > 0 ? -1 : 1

			if (this.index == (this.paginateMax) && diffValue > 0){ // LIMIT SUP
				if (this.ClientDevice == 'desktop' || this.$client.isOldIos()){ // desktop : retour index début
					// this.index = 1
					this.restartSlider()
				}else { // mobile/tablet : pas d'action
					//
					this.restartSlider()

				}

			}else if (this.index == 1 && diffValue < 0){	// LIMIT INF
				if (this.ClientDevice == 'desktop' || this.$client.isOldIos()){ // desktop : retour index max
					// this.index = this.paginateMax
					this.reverseSlider()
				}else { // mobile/tablet : pas d'action
					//
					this.reverseSlider()
				}

			}else {

				this.index += diffValue
				this.legendeContent = this.content[this.index - 1].legende
			}

			// this.legendeContent = this.content[this.index - 1].legende

		}
	},
	computed:{

		paginateMax: function(){
			return this.content.length
		},
		whatThumbWidth: function(){
			return 100 * this.content.length + 400 + 'vw'
		},

		whatPosition: function(){
			return 'translate(' + -100 * this.index + 'vw, 0)'
		},
		whatTranslate: function(){

			// if (this.touchMoveIsActive && this.touchSliderActive){ // securité parent : si touchDirection vertical
			if (this.touchSliderActive){ // securité parent : si 

				if (this.index == 1 && -this.sliderTranslate > 0){ // bloque index inferieur
					return 0 + 'px'
				}else if (this.index == this.paginateMax && -this.sliderTranslate < 0){   // bloque index superieur
					return 0 + 'px'
				}else {
					return - this.sliderTranslate + 'px'
				}

			}else {
				return 0 + 'px'
			}
		}
	},
	methods: {
		// testToucheMove(e){

		// 	// this.$el.children[0].style.transition = 'transform 0.2s'

		// 	// if (!this.touchMovePosition){
		// 	// 	this.touchMovePosition = e.touches[0].clientX
		// 	// }else {
		// 	// 	this.sliderTranslate = this.touchMovePosition - e.touches[0].clientX
		// 	// }

		// 	if (this.touchSliderActive && !(this.$client.isOldIos())){

		// 		this.$el.children[0].style.transition = 'transform 0.2s ease'

		// 		if (!this.touchMovePosition){
		// 			this.touchMovePosition = e.touches[0].clientX
		// 		}else {
		// 			this.sliderTranslate = this.touchMovePosition - e.touches[0].clientX
		// 		}
		// 	}
		// },
		desktopClick(direction){
			// this.controlIndex = this.controlIndex + 1
			// this.clickCount = this.clickCount + 1

			this.controlIndex = this.controlIndex + direction
			this.clickCount = this.clickCount + direction
		},
    restartSlider: function(){

      this.$refs.slider.style.transition = 'transform 0s ease'; 
      this.index = 0
      this.legendeContent = this.content[0].legende

      setTimeout(() => { 
        this.$refs.slider.style.transition = 'transform 0.2s ease'; 
        this.index = 1
      }, 20);
    },

    reverseSlider: function(){

      this.$refs.slider.style.transition = 'transform 0s ease'; 
      this.index = this.content.length + 1
      this.legendeContent = this.content[this.content.length - 1].legende

      setTimeout(() => { 
        this.$refs.slider.style.transition = 'transform 0.2s ease'; 
        this.index = this.content.length
      }, 20);
    },

		sendScrollData(dimension){
		this.$emit('send-height', {viewer: dimension})
		},
	},
  mounted(){
    this.$tools.setResizeObserver(this.$el, () => {
      // console.log('MAINARTICLE SEND HEIGHT')
      this.sendScrollData(this.$el.getBoundingClientRect().height)
    })
  }
}
</script>

<style lang="scss">
@import '@/css/variables';

.MainViewerMobileTape{
	position: relative;
	width: 100%;
	height: calc(90vh - var(--menu-header-height));

	background-color: var(--article-color-lighten);

	overflow: hidden;

	&--thumbs{
		// display: flex;
		// flex-wrap: nowrap;
		display: inline-block;
		// width: 2000vw;
		vertical-align:top;

		height: 75%;
		// width: 1000vw;
		// height: auto;		
		
		position: relative;

		transition: transform 0.3s;


		// left: 20px;
		// transform: translate(-93vw, 0);

		&--element{
			width: calc(100vw);
			max-height: 100%;
			object-fit: cover;
			vertical-align:top;
		}
	}

	&--pagination{
		height: 6%;
		font-size: var(--article-font-legende);
		text-align: center; 
		font-family: 'vd-reg';

		position: relative;

		margin-left: var(--esp-big);
		margin-right: var(--esp-big);


    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

		p {
			width: 100%;
			line-height: 1;
		}

	}

	&--legende{
		font-family: 'vd-reg';
		font-size: var(--article-font-legende);
		
		text-align: center; 

		position: relative;  
    line-height: 1.2;

 
    margin: var(--esp-big);
    overflow: scroll;

    height: calc(25% - (2 * var(--esp-big)));

		&--container{

			// margin: var(--esp-big);
			// height: calc(100% - (2 * var(--esp-big)));
			// background-color: purple;

			&--pagination{


			}

			&--content{
				margin-top: var(--esp-med);

				// background-color: green;
			}
		}
	}
}


.MainViewerMobileTape--touch{
	position: absolute;

	width: 100%;
	height: 70%;

	z-index: 500;

	display: flex;

	// background-color: red;

	&--left{
		width: 50%;
		height: 100%;

		// background-color: purple;

	}

	&--right{
		width: 50%;
		height: 100%;

		// background-color: orange;	
	}
}

.MainViewerMobileTape--legende--container--content{
  & em{
    font-family: 'vd-ita';
  }
}


</style>